import React, { useState } from 'react';
import {Link} from 'gatsby'
import PP_Navbar from '../../../components/PP_Navbar';
import Layout from '../../../components/Layout';
import Modal from '../../../components/Modal';

function Index(props) {
    const [modalOpen, setModal] = useState(false)
    const closeModal = () => {
        setModal(false)
    }
    let styles={
        section3:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649426958/cwa/assets/images/Partner%20Program/Sales%20Advantage/leaf-photo1_nvftlh.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        },
        section5:{
            backgroundImage:"url('https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649428084/cwa/assets/images/Partner%20Program/Sales%20Advantage/wood-desk-photo1_txvq6t.jpg')",
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
    return (
        <div>
            <Layout location={'Partner Program'} title={'Sales Advantage'}>
            {modalOpen ? <Modal fill={'Partner'} closeFn={closeModal} />:null}
            <PP_Navbar location={'Sales Advantage'}/> 
            <main className='cw-bg-greengradient py-10 px-5'>
                <h1 className='text-white font-bold text-5xl text-center'>The CWA Sales Advantage</h1>
            </main>
            <section className='py-10 w-100'>
                <div className='mx-auto max-w-3xl text-center'>
                    <h3 className='cw-text-green text-3xl font-bold text-center'>Giving you an edge in the field</h3>
                    <p className='font-serif text-xl py-3 px-5 text-left leading-8'>
                        Our commitment to your success goes beyond giving you exceptional training. We give you an extra edge simply
                        by being the best at what we do. That means we're always up to date with the latest in point of sale products 
                        and terminals. It means our innovative payment solutions stay that way by constantly evolving. It means we 
                        continuously work to refine and expand our own brand of value-added services. But most importantly, as a CWA 
                        sales agent, it means you're uniquely able to offer merchants a combination of solutions of services that they won't 
                        get with anyone else — and that's the easiest way to turn prospects into closed deals.
                    </p>
                    <button onClick={()=>setModal(true)} className='cw-bg-lightgreen p-3 font-semibold text-white text-lg rounded'>Contact Us Now</button>
                </div>
            </section>
            <section className='cw-bg-lightgreen pt-10 px-5'>
                <div className='mx-auto max-w-3xl'>
                    <h3 className='text-white text-3xl font-bold text-center mb-3'>Open up new leads, close more deals</h3>
                    <p className='font-serif text-white text-xl py-3 text-left leading-8 '>
                        At CWA we've developed our own customized CRM tool. It was designed to help our sales partners build their pipeline
                        and manage their portfolio. The app is free for all of our agents and we provide the training. Unsure of what all of this means? 
                        See what turning your smartphone into a mobile sales office can do for you.
                    </p>
                    <div className='grid grid-cols-1 lg:grid-cols-2'>
                        <ul className='text-white font-serif list-disc space-y-3 pt-4 pl-2'>
                            <li>Synch with your email, calendar and more</li>
                            <li>Boost productivity and grow your pipeline</li>
                            <li>Manage client activity and communications</li>
                            <li>Organize projects and track sales leads</li>
                            <li>Automate your sales process</li>
                        </ul>
                        <div className='flex justify-center relative mt-3'>
                            <img alt='' className='' style={{maxHeight:'260px'}} src='https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649425598/cwa/assets/images/Partner%20Program/Sales%20Advantage/half-device_baree6.png'/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-10 px-5" style={styles.section3}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div></div>
                        <div className="relative text-white max-w-md">
                            <h4 className='font-bold text-lg pb-3'>Turn your booming career into a blossoming one… with room to grow</h4>
                            <p className='font-serif'>
                                A lot of people wouldn't trade their job in sales for anything — and we can't say we blame them. Sales is just part of some people's DNA.
                                But for every dozen or so individuals for whom sales is a dream job, there's an agent interested in taking their career to the next level. 
                                At CWA, we give our sales partners the opportunity to become sales managers. We evaluate the performance of our entire salesforce to 
                                seek out agents who show both tremendous promise and a knack for leadership. We then offer those partners the chance to to manage their own 
                                sales team, and earn percentages on each of their agents accounts.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5">
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div className="py-10 max-w-md">
                            <h2 className="cw-text-green font-bold text-lg">It's all about who you know… Leverage our relationships, build your portfolio</h2>
                            <p className="py-4 font-serif">
                                We have partnerships with some of the industry's top equipment and software companies. As a CWA sales agent, you'll be able 
                                to take advantage of these partnerships in your dealings with both potential and current clients — offering them the benefit of added 
                                value while earning additional bonus revenue for yourself. Utilizing these partnerships in your sales pitch can also help you steer the 
                                conversation away from rates should you be met with resistance. Instead allowing you to focus on all that CWA can do to improve 
                                their business.
                            </p>
                        </div>
                        <div className="flex justify-center">
                            <img alt='' style={{maxHeight:'100%', maxWidth:'365px', width:'auto'}} src="https://res.cloudinary.com/dg8d3kpjx/image/upload/v1649427786/cwa/assets/images/Partner%20Program/Sales%20Advantage/partner-network_dmzlrw.png"/>
                        </div>
                    </div>  
                </div>
            </section>
            <section className="py-10 px-5" style={styles.section5}>
                <div className="max-w-5xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-2">
                        <div></div>
                        <div className="relative text-white max-w-md">
                            <h4 className='font-bold text-lg pb-3'>Your own personal ad agency</h4>
                            <p className='font-serif'>
                                We'll help you sell merchants on us, by first selling them on yourself. From your first day on the job, you'll 
                                have access to our entire catalog of helpful marketing material. And if you can't find what you need in there, 
                                all you have to do is send in a request and our in-house creative department will make it for you. Just think of us 
                                as your own personal ad agency, here to help you close merchants any way we can.
                            </p>
                        </div>
                    </div>  
                </div>
            </section>
            <section className='bg-gray-700 py-10 px-5'>
                <div className='mx-auto max-w-4xl'>
                    <h3 className='text-white text-3xl font-bold text-center'>CWA Agents are the best at what they do.</h3>
                    <p className='text-white font-serif text-xl py-3 text-left leading-8 text-center mb-3'>
                        Their efforts are at the very heart of our success — and that's why we believe in rewarding them with 
                        one of the most <b>lucrative bonus structures in the industry:</b>
                    </p>
                    <div className='grid grid-cols-1 lg:grid-cols-3 space-y-4 lg:space-y-0 lg:space-x-4 '>
                        <div className='text-center bg-gray-200 py-5 px-4 space-y-4'>
                            <h4 className='text-lg font-bold'>Up-Front Bonuses</h4>
                            <p>Our sales agents get paid an upfront bonus for every qualifying account they sign.</p>
                        </div>
                        <div className='text-center bg-gray-200 py-5 px-4 space-y-4'>
                            <h4 className='text-lg font-bold'>The Winner's Circle</h4>
                            <p className='font-serif'>
                                The CWA Winner's Circle presents an added opportunity for our sales agents to boost 
                                their bonus income. Winner's Circle bonuses are paid out at the end of each month and are in addition to up-front bonuses..
                            </p>
                            <div>
                                <p className='cw-text-green text-3xl font-bold'>$300</p>
                                <p>5 New Accounts</p>
                            </div>
                            <div>
                                <p className='cw-text-green text-3xl font-bold'>$1000</p>
                                <p>10 New Accounts</p>
                            </div>
                            <div>
                                <p className='cw-text-green text-3xl font-bold'>$2500</p>
                                <p>20 New Accounts</p>
                            </div>
                        </div>
                        <div className='text-center bg-gray-200 py-5 px-4 space-y-4'>
                            <h4 className='text-lg font-bold'>The Race to 30 and the Race to 50</h4>
                            <p className='font-serif '>
                            After six months with CWA, all sales agents are eligible for our Race to 30 and Race to 50 bonuses. 
                            Like the Winner's Circle, they are paid out at the end of the month and come in addition to any other bonuses 
                            and residuals.
                            </p>
                            <div>
                                <p className='cw-text-green text-3xl font-bold'>$1000</p>
                                <p>30 Accounts in 6 Months</p>
                            </div>
                            <div>
                                <p className='cw-text-green text-3xl font-bold'>$2000</p>
                                <p>50 Accounts in 6 Months</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="cw-bg-greengradient">
                <div className="px-5 text-center lg:text-left flex lg:justify-between py-5 align-center max-w-5xl mx-auto space-y-3">
                    <h3 className="text-white text-xl font-serif max-w-xl leading-8">Want to know more about what it takes to be a CWA sales agent? Check out our training section.</h3>
                    <button className="transition ease-in-out delay-150 border-2 border-white p-2 text-white hover:bg-white hover:cw-text-green font-semibold text-lg rounded">Go to Training</button>
                </div>
            </section> */}
            </Layout>
        </div>
    );
}

export default Index;